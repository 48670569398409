
import Axios from "axios"
import { DateTime } from "luxon";
import moment from "moment"
import * as XLSX from 'xlsx'




//get location hierarchy data based on location [],roleassigned [], $roles[]
const getLocationData = (locationData, roleAssignments, roles) => {
    // Filter role assignments based on roles
    const relevantAssignments = roleAssignments.filter(assignment =>
        assignment.roles.some(role => roles.includes(role))
    );

    // Initialize the result as an empty array
    let result = [];

    // Loop through each relevant assignment
    relevantAssignments.forEach(assignment => {
        if (assignment.tier1_id === 0) {
            // If tier1_id is 0, return all locations
            result = locationData;
        } else {
            // Find the matching tier1 (country)
            let country = locationData.find(loc => loc.id === assignment.tier1_id);
            if (!country) return;

            if (assignment.tier2_id === 0) {
                // If tier2_id is 0, return the country and its regions
                result.push(country);
            } else {
                // Find the matching tier2 (region)
                let region = country.locationTwos.find(loc => loc.id === assignment.tier2_id);
                if (!region) return;

                if (assignment.tier3_id === 0) {
                    // If tier3_id is 0, return the region and its sites
                    result.push({
                        ...country,
                        locationTwos: [region]
                    });
                } else {
                    // Find the matching tier3 (site)
                    let site = region.locationThrees.find(loc => loc.id === assignment.tier3_id);
                    if (!site) return;

                    result.push({
                        ...country,
                        locationTwos: [{
                            ...region,
                            locationThrees: [site]
                        }]
                    });
                }
            }
        }
    });

    // Remove duplicate locations from the result
    result = result.filter((item, index, self) =>
        index === self.findIndex(t => t.id === item.id)
    );

    return result;
}
function checkRoleAccessByRoleIds(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId) {
    // Step 1: Filter roleAssignments based on user_ids and roles
    const filteredAssignments = roleAssignments.filter(assignment =>
        user_ids.includes(assignment.user_id) &&
        assignment.roles.some(role => roles.includes(role))
    );

    // Step 2: Create a set of user IDs who have access
    const userIdsWithAccess = new Set();
    if (adminId) {
        userIdsWithAccess.add(adminId);
    }

    // Step 3: Check each filtered assignment for access
    filteredAssignments.forEach(assignment => {
        const { user_id, tier1_id, tier2_id, tier3_id } = assignment;

        // Get valid tier IDs for this specific assignment
        const validTierIds = getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData);

        if (level === 0) {
            // Global access
            userIdsWithAccess.add(user_id);
        } else if (level === 1 && validTierIds.countries.includes(tier_id) && !assignment.tier2_id) {
            // Country level access
            userIdsWithAccess.add(user_id);
        } else if (level === 2 && (validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id)) && !assignment.tier3_id) {
            // Region level access
            userIdsWithAccess.add(user_id);
        } else if (level === 3 && (validTierIds.businessUnits.includes(tier_id) || validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id))) {
            // Business Unit level access
            userIdsWithAccess.add(user_id);
        }
    });

    // Step 4: Return the list of user IDs who have access
    return Array.from(userIdsWithAccess);
}

function getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData) {
    let countries = new Set();
    let regions = new Set();
    let businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === country.id || tier1_id === 0 || tier1_id === null) {
            countries.add(country.id);
            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id || tier2_id === null) {
                    regions.add(region.id);
                    region.locationThrees.forEach(bu => {
                        if (tier3_id === 0 || tier3_id === bu.id || tier3_id === null) {
                            businessUnits.add(bu.id);
                        }
                    });
                }
            });
        }
    });

    return {
        countries: Array.from(countries),
        regions: Array.from(regions),
        businessUnits: Array.from(businessUnits)
    };
}
const getRPTextFormat = (item) => {
    if (item.length !== 0) {
        if (item.length >= 2) {

            const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
            const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('LLL-yyyy');
            return `${startDate} to ${endDate}`;
        } else {
            return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
        }
    }
};
const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
    const countries = new Set();
    const regions = new Set();
    const businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === 0 || tier1_id === country.id) {
            countries.add(country.id);

            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id) {
                    regions.add(region.id);

                    region.locationThrees.forEach(businessUnit => {
                        if (tier3_id === 0 || (tier2_id === 0 && tier3_id === null) || tier3_id === businessUnit.id) {
                            businessUnits.add(businessUnit.id);
                        }
                    });
                }
            });
        }
    });

    return { countries: Array.from(countries), regions: Array.from(regions), businessUnits: Array.from(businessUnits) };
};
const filterDataByTierAndLocationByLevel = (data, locationData, tier1_id, tier2_id, tier3_id) => {
    console.log(tier1_id, tier2_id, tier3_id)
    if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
        return data; // If tier is 0, null, null return the given data
    }

    const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);
    console.log(countries, regions, businessUnits)

    return data.filter(item => {
        if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
            // Case when we want all regions and sites under a country
            return (item.level === 1 && countries.includes(item.locationId)) ||
                (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
            // Case when we want a specific region and all its sites
            return (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
            // Case when we want a specific site
            return item.level === 3 && businessUnits.includes(item.locationId);
        } else {
            // Case when we want only the specific country
            return item.level === 1 && countries.includes(item.locationId);
        }
    });
};
function getFiscalYearsFromStartDate(start_date, fymonth) {
    const startDate = DateTime.fromISO(start_date, { zone: "Asia/Calcutta" })
    const currentDate = DateTime.local();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
        // When fiscal month is January, fiscal year matches the calendar year
        startFiscalYear = startDate.year;
        currentFiscalYear = currentDate.year;

        // Add fiscal years only up to the current or next year if already in January
        for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
            fiscalYears.push({ name: year, label: `${year}` });
        }
    } else {
        // Normal fiscal year spanning two calendar years
        startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
        currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

        // Add fiscal years from the starting fiscal year to the current fiscal year + 1
        for (let year = startFiscalYear; year <= currentFiscalYear + 1; year++) {
            const label = `${year}-${(year + 1).toString().slice(-2)}`;
            fiscalYears.push({ name: year + 1, label });
        }

        // Exclude future fiscal years if the current month is before the fiscal start month
        if (currentDate.month < fymonth) {
            fiscalYears.pop();
        }
    }

    return fiscalYears;
}
function isDateInFiscalYear(year, dateString, fymonth) {
    const { start, end } = parseDateString(dateString);
    let curYear = year
    if (fymonth !== 1) {
        curYear = year - 1

    }
    const startDate = DateTime.fromFormat(start.trim(), 'MMM-yyyy');
    const endDate = DateTime.fromFormat(end.trim(), 'MMM-yyyy');
    const fiscalYearStart = DateTime.fromObject({ year: curYear, month: fymonth, day: 1 }); // April 1 of the previous year
    const fiscalYearEnd = DateTime.fromObject({ year: year, month: fymonth - 1 || 12, day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 }).daysInMonth }); // March 31 of the given year
    console.log((startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
        (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
        (startDate <= fiscalYearStart && endDate >= fiscalYearEnd), dateString, curYear, year)
    return (
        (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
        (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
        (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
    );
}
function parseDateString(dateString) {
    if (dateString.includes('to')) {
        const [start, end] = dateString.split('to');
        return { start, end };
    } else {
        return { start: dateString, end: dateString };
    }
}
function getReportingFiscalYearByReportingperiod(reporting_period, fymonth) {
    const parseDate = (str) => {
        // Try both formats to parse different date strings
        return DateTime.fromFormat(str, "MMM-yyyy").isValid
            ? DateTime.fromFormat(str, "MMM-yyyy")
            : DateTime.fromFormat(str, "MM-yyyy");
    };

    const determineFiscalYear = (date) => {
        // Determine fiscal year based on fymonth
        if (fymonth === 1) {
            return date.year; // Calendar year if fiscal year starts in January
        } else {
            const fiscalYearStart = DateTime.fromObject({ year: date.year, month: fymonth, day: 1 });
            return date >= fiscalYearStart ? date.year + 1 : date.year;
        }
    };

    // If reporting_period is an array, parse the last date in the array
    if (Array.isArray(reporting_period)) {
        const lastPeriod = reporting_period[reporting_period.length - 1];
        const date = parseDate(lastPeriod);
        return date.isValid ? determineFiscalYear(date) : null;
    }

    // If reporting_period is a single date or range string
    if (typeof reporting_period === "string") {
        if (reporting_period.includes(" to ")) {
            // Parse the end date in the range "Jan-2022 to Mar-2022"
            const [, endDateStr] = reporting_period.split(" to ");
            const endDate = parseDate(endDateStr.trim());
            return endDate.isValid ? determineFiscalYear(endDate) : null;
        } else {
            // Single date format, e.g., "Jan-2022" or "02-2022"
            const date = parseDate(reporting_period);
            return date.isValid ? determineFiscalYear(date) : null;
        }
    }

    return null; // Return null if format is invalid
}
const adjustColumnWidths = (worksheet) => {
    const colWidths = [];

    // Calculate maximum width for each column
    for (const [key, value] of Object.entries(worksheet)) {
        if (key[0] === '!') continue;

        const col = XLSX.utils.decode_cell(key).c;

        const cellValue = value.v != null ? value.v.toString() : '';
        const cellWidth = cellValue.length;

        if (!colWidths[col] || colWidths[col] < cellWidth) {
            colWidths[col] = cellWidth;
        }
    }

    // Apply calculated widths to worksheet columns
    worksheet['!cols'] = colWidths.map(width => ({ width: width + 2 })); // Add some padding
};
export { getLocationData, checkRoleAccessByRoleIds, getRPTextFormat, filterDataByTierAndLocationByLevel, getFiscalYearsFromStartDate, isDateInFiscalYear, getReportingFiscalYearByReportingperiod,adjustColumnWidths }